.slidea .slidea-control {
  display: block;
  position: absolute;
  top: 50%;
  z-index: 3;
}

.slidea .slidea-control.slidea-next {
  right: 0;
}

.slidea .slidea-control.slidea-next .slidea-control-text {
  float: right;
}

.slidea .slidea-control.slidea-next .slidea-control-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transform: translateX(40px);
  -moz-transform: translateX(40px);
  -ms-transform: translateX(40px);
  -o-transform: translateX(40px);
  transform: translateX(40px);
}

.slidea .slidea-control.slidea-next:hover .slidea-control-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.slidea .slidea-control.slidea-prev {
  left: 0;
}

.slidea .slidea-control.slidea-prev .slidea-control-text {
  float: left;
}

.slidea .slidea-control.slidea-prev .slidea-control-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.slidea .slidea-control.slidea-prev:hover .slidea-control-thumbnail {
  -ms-filter: "progid:DXImageTransform@mixin Microsoft@mixin Alpha(Opacity=${iefactor})";
  filter: "alpha(opacity=(${iefactor}))";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}

.slidea .slidea-control .slidea-control-inner {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-text {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;
  position: relative;
  font-weight: bold;
  line-height: 80px;
  text-align: center;
  width: 40px;
  height: 80px;
  z-index: 5;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-text:hover {
  text-decoration: none;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-thumbnail {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;
  position: absolute;
  height: 100%;
}

.slidea .slidea-control .slidea-control-inner .slidea-control-thumbnail .slidea-control-image {
  display: block;
  width: auto;
  height: 100%;
}

.slidea-loaded {
  overflow: visible;
}

.slidea-loader-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.slidea-loader-wrapper .slidea-loader-text {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.slidea-loader-wrapper .slidea-loader-text .slidea-loader-title {
  margin-bottom: 0;
}

.slidea-loader-wrapper .slidea-loader-text .slidea-loader-subtitle {
  margin-bottom: 0;
}

.slidea-loader-wrapper .slidea-loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
}

.slidea-loader-wrapper .slidea-loader .slidea-loader-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  float: left;
  width: 20%;
}

.slidea .slidea-progress {
  display: block;
  position: absolute;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 10px;
  z-index: 3;
}

.slidea .slidea-progress.slidea-progress-top {
  top: 0;
}

.slidea .slidea-progress.slidea-progress-bottom {
  bottom: 0;
}

.slidea .slidea-progress .slidea-progress-bar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 10px;
}

.slidea-pagination {
  display: block;
  text-align: center;
  width: 100%;
  height: auto;
  z-index: 3;
}

.slidea-pagination.slidea-pagination-top, .slidea-pagination.slidea-pagination-bottom {
  position: absolute;
  height: 20px;
  margin: 5px 0;
}

.slidea-pagination.slidea-pagination-top {
  top: 1.5rem;
}

.slidea-pagination.slidea-pagination-top .slidea-pagination-item span {
  top: 20px;
  transform: rotateZ(30deg);
}

.slidea-pagination.slidea-pagination-bottom {
  bottom: 1.5rem;
}

.slidea-pagination.slidea-pagination-bottom .slidea-pagination-item span {
  top: -20px;
  transform: rotateZ(-30deg);
}

.slidea-pagination.slidea-pagination-left, .slidea-pagination.slidea-pagination-right {
  position: absolute;
  width: 10px;
}

.slidea-pagination.slidea-pagination-left {
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
}

.slidea-pagination.slidea-pagination-left .slidea-pagination-item span {
  left: 20px;
}

.slidea-pagination.slidea-pagination-right {
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
}

.slidea-pagination.slidea-pagination-right .slidea-pagination-item span {
  right: 20px;
}

.slidea-pagination.slidea-pagination-before, .slidea-pagination.slidea-pagination-after {
  display: block;
  position: relative;
  margin: 25px auto;
}

.slidea-pagination .slidea-pagination-item {
  position: relative;
  margin: 10px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.slidea-pagination .slidea-pagination-item span {
  position: absolute;
  white-space: nowrap;
  top: 0;
}

.slidea-pagination .slidea-pagination-item::before {
  content: "";
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-clip: padding-box;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  width: 10px;
  height: 10px;
  z-index: 999;
}

.slidea-pagination .slidea-pagination-item::before.active {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  background: white;
}

.slidea-thumbnails {
  position: relative;
  display: block;
  overflow: hidden;
}

.slidea-thumbnails.horizontal {
  width: 100%;
  height: auto;
}

.slidea-thumbnails.horizontal .slidea-thumbnail {
  width: 100%;
  height: auto;
}

.slidea-thumbnails.vertical {
  width: 100%;
  height: 100%;
}

.slidea-thumbnails.vertical .slidea-thumbnail-wrapper {
  display: block;
  position: relative;
}

.slidea-thumbnails.vertical .slidea-thumbnail {
  width: auto;
  height: 100%;
}

.slidea-thumbnails-inner {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  text-align: center;
}

.slidea-thumbnails-inner.animating {
  transition: transform 0.5s ease;
}

.slidea-thumbnails-inner .slidea-thumbnail-wrapper {
  height: auto;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.slidea-thumbnails-inner .slidea-thumbnail-wrapper .slidea-thumbnail {
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
}

.slidea-with-thumbnails {
  position: relative;
  display: block;
  width: 100%;
}

.slidea-with-thumbnails .slidea-with-thumbnails-container,
.slidea-with-thumbnails .slidea-thumbnails-container {
  position: relative;
  display: block;
  box-sizing: border-box;
}

.slidea-with-thumbnails .slidea-thumbnails-container {
  overflow: hidden;
}

.slidea-with-thumbnails.left .slidea-thumbnails-container {
  left: 0;
}

.slidea-with-thumbnails.right .slidea-thumbnails-container {
  right: 0;
}

.slidea-with-thumbnails.left .slidea-thumbnails-container, .slidea-with-thumbnails.right .slidea-thumbnails-container {
  height: 100%;
  position: absolute;
  z-index: 100;
  top: 0;
}

.slidea-with-thumbnails.top .slidea-thumbnails-container, .slidea-with-thumbnails.bottom .slidea-thumbnails-container {
  height: auto;
  width: 100%;
  position: relative;
}

.slidea {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 700px;
  overflow: hidden;
  transform-style: preserve-3d;
}

.slidea:after {
  content: "";
  display: table;
  clear: both;
}

.slidea .slidea-canvas {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-background-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-content-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 999;
  overflow: auto;
}

.slidea .slidea-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: table;
}

.slidea .slidea-content.slidea-content-center > .slidea-content-container {
  display: table-cell;
  vertical-align: middle;
}

.slidea .slidea-content.slidea-content-top > .slidea-content-container {
  display: table-cell;
  vertical-align: top;
}

.slidea .slidea-content.slidea-content-bottom > .slidea-content-container {
  display: table-cell;
  vertical-align: bottom;
}

.slidea .slidea-inner {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -ms-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slidea .slidea-layer-wrapper {
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
}

.slidea .slidea-layer-wrapper .slidea-layer {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.slidea .slidea-layer-wrapper .slidea-layer.slidea-video-layer {
  height: 100%;
}

.slidea .slidea-layer-wrapper img.slidea-layer {
  height: 100%;
}

.slidea.slidea-loaded {
  overflow: visible;
}

.slidea .slidea-loader-wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.slidea .slidea-loader-wrapper .slidea-loader-text {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.slidea .slidea-loader-wrapper .slidea-loader-text .slidea-loader-title {
  margin-bottom: 0;
}

.slidea .slidea-loader-wrapper .slidea-loader-text .slidea-loader-subtitle {
  margin-bottom: 0;
}

.slidea .slidea-loader-wrapper .slidea-loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.slidea .slidea-loader-wrapper .slidea-loader .slidea-loader-inner .slidea-loader-tile {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  float: left;
  width: 20%;
}

.slidea .slidea-object {
  opacity: 0;
}

.slidea .slidea-object.object-absolute {
  position: absolute;
  width: 100%;
}

.slidea *[data-slidea-href] {
  cursor: pointer;
}

.slidea .slidea-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 4;
  background: rgba(0, 0, 0, 0.5);
}

.slidea .slidea-overlay.slidea-overlay-to-top {
  background: linear-gradient(to top, transparent, black);
}

.slidea .slidea-overlay.slidea-overlay-to-bottom {
  background: linear-gradient(to bottom, transparent, black);
}

.slidea .slidea-overlay.slidea-overlay-to-left {
  background: linear-gradient(to left, transparent, black);
}

.slidea .slidea-overlay.slidea-overlay-to-right {
  background: linear-gradient(to right, transparent, black);
}

.slidea .slidea-overlay.slidea-overlay-to-top-left {
  background: linear-gradient(to top left, transparent, black);
}

.slidea .slidea-overlay.slidea-overlay-to-top-right {
  background: linear-gradient(to top right, transparent, black);
}

.slidea .slidea-overlay.slidea-overlay-to-bottom-left {
  background: linear-gradient(to bottom left, transparent, black);
}

.slidea .slidea-overlay.slidea-overlay-to-bottom-right {
  background: linear-gradient(to bottom right, transparent, black);
}

.slidea .slidea-slide {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);
  perspective: 300;
  opacity: 0;
  backface-visibility: hidden;
}

.slidea .slidea-slide.active {
  display: block;
  z-index: 2;
}

.slidea .slidea-slide.previous {
  display: block;
  z-index: 1;
}

.slidea.slidea-dragging {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: move;
}

.slidea iframe {
  border: none;
}

.slidea .slidea-video-background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: black;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.slidea .slidea-video {
  width: 100% !important;
  height: 100% !important;
}

.slidea .slidea-video-cover {
  display: block;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.slidea .slidea-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.slidea .slidea-wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.slidea.slidea-content-layout {
  width: 100%;
  height: auto;
  background: transparent !important;
}

.slidea.slidea-content-layout .slidea-loader-wrapper {
  background: transparent !important;
}

.slidea.slidea-content-layout .slidea-inner {
  overflow: hidden !important;
}

.slidea.slidea-content-layout .slidea-content {
  display: block !important;
  position: relative !important;
  width: 100% !important;
  height: auto !important;
  box-sizing: border-box !important;
}

.slidea.slidea-content-layout .slidea-content-container {
  width: 100% !important;
  display: inline-block !important;
}

.slidea.slidea-content-layout .slidea-content-wrapper {
  overflow: visible !important;
}

.slidea.slidea-default-layout {
  width: 100%;
  height: auto;
}

.slidea.slidea-fluid-layout {
  width: 100%;
  height: 100%;
  display: block;
}
